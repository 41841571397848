// @ts-ignore
if (!window.APPSettings) {
  // @ts-ignore
  window.APPSettings = {
    realmSettings: {},
    showBuildVersion: true,
    name: 'test',
  };
}

// @ts-ignore
export default window.APPSettings;
