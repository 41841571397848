import Keycloak from 'keycloak-js';
import mitt from 'mitt';
import APPSettings from './settings';

const emitter = mitt();

const eventBus = {
  ...emitter,
  $on: emitter.on,
  $off: emitter.off,
  $emit: emitter.emit,
};

class Session {
  isAuthenticated = false;

  userInfo = {};

  keycloak: Keycloak;

  refreshPromise?: Promise<any> | null = null;

  constructor() {
    this.keycloak = new Keycloak(APPSettings.realmSettings);
  }

  async loadUserInfo() {
    const userInfo = await this.keycloak.loadUserInfo();
    this.userInfo = userInfo;
    eventBus.$emit('change:userInfo', userInfo);
  }

  async initKeycloak(onLoad: Keycloak.KeycloakOnLoad = 'login-required') {
    this.isAuthenticated = await this.keycloak.init({ onLoad });
    return this.isAuthenticated;
  }

  check() {
    return this.initKeycloak('check-sso');
  }

  async login() {
    await this.initKeycloak();
    await this.loadUserInfo();
    eventBus.$emit('login:success');
  }

  async logout(beforeLogout = Promise.resolve(true)) {
    this.isAuthenticated = false;
    // await for the action that needs to take place before logout
    await beforeLogout;
    this.keycloak.logout();
  }

  async renew(minValidity = 0) {
    const refreshed = await this.keycloak.updateToken(minValidity);
    if (refreshed) {
      eventBus.$emit('token:refresh');
    }
    return refreshed;
  }

  async checkValidity() {
    // cache the check promise, to avoid duplicate calls when more simultaneous calls are made
    if (this.keycloak.tokenParsed) {
      const { exp = 0, iat = 0 } = this.keycloak.tokenParsed;
      const minValidity = (exp - iat) / 60;
      if (!this.refreshPromise) {
        // refresh token
        this.refreshPromise = this.renew(minValidity);
      }
      try {
        await this.refreshPromise;
      } finally {
        delete this.refreshPromise;
      }
      return true;
    }
    return true;
  }
}

export { eventBus };

export default Session;
