import auth from '@/components/cao-ui-auth/src/index';

import '@/styles/loading.less';
import './registerServiceWorker';

const authPromise = auth.init();
authPromise.then(async () => {
  const { default: mount } = await import(/* webpackChunkName: "mount" */ `./mount?v=${VERSION}`);
  mount(authPromise);
});
