import axios from 'axios';
import toastr from 'toastr';
import bootbox from 'bootbox';
import Auth from './auth';
import APPSettings from './settings';

const entityMap: {
  [k: string]: string
} = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;',
};

function escapeHtml(string: string) {
  return String(string).replace(/[&<>"'`=/]/g, (s) => entityMap[s]);
}

function strip(html: string) {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent ?? tmp.innerText ?? '';
}

function getDetails(rjs: any) {
  let details = '';
  if (rjs.message) {
    details = rjs.message;
  } else if (rjs.Error) {
    details = rjs.Error;
  } else if (rjs.ResponseStatus?.Message) {
    details = rjs.ResponseStatus.Message;
    const regex = /"Error":\s"(.*)"/i;
    const matches = regex.exec(details);
    if (matches) {
      ([, details] = matches);
    }
  } else if (rjs.responseStatus?.message) {
    details = escapeHtml(rjs.responseStatus.message);
  } else if (rjs.errorMessage) {
    if (typeof rjs.errorMessage === 'object' && rjs.errorMessage !== null) {
      const errorValues: string[][] = Object.values(rjs.errorMessage);
      details = errorValues.map(
        (row: string[]) => row
          .map((colText) => `${escapeHtml(colText)}`)
          .join('\n'),
      )
        .join('\n');
    } else {
      details = escapeHtml(rjs.errorMessage);
    }
  } else if (rjs?.error?.message) {
    const value = rjs.error?.msgValues?.length
      ? ` Values: ${rjs.error.msgValues.join(', ')}`
      : '';
    details = `${rjs.error.message}${value}`;
  }

  return details;
}

function getMyErrorMessage(err: any) {
  if (!err) {
    return null;
  }
  // handle error messages in response data
  try {
    let message;
    if (err?.config?.errorMessage) {
      // overwrite message with the custom one, if there is one
      message = err.config.errorMessage;
    } else {
      message = 'Could not process the request.';
    }
    let rjs = err.response.data;
    if (err?.request?.responseText) {
      try {
        rjs = JSON.parse(err.request.responseText);
      } catch (e) {
        return strip(err.request.responseText || err.message);
      }
    }
    if (rjs) {
      let details = getDetails(rjs);
      if (!details) {
        details = JSON.stringify(rjs);
      }
      message += `\nMore technical details: <br/> ${strip(details)}`;
    }
    return message;
  } catch (e) {
    return err.request.statusText;
  }
}

function getErrorInfo(err: any) {
  const errorInfo = {
    url: err.config.url,
    method: err.config.method,
    status: err.request.status,
    statusText: err.request.statusText,
    responseText: undefined,
    data: {},
  };
  try {
    errorInfo.responseText = err.request.responseText;
  } catch (e) { }
  try {
    errorInfo.data = JSON.parse(err.config.data) || {};
  } catch (e) { }
  return JSON.stringify(errorInfo, null, 2);
}

export { getMyErrorMessage };

export default function (auth: Auth) {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (config) => new Promise(((resolve, reject) => {
      auth.session.checkValidity()
        .then(() => {
          const conf = { ...config };
          conf.headers = {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            ...conf.headers,
          };
          if (auth.session?.keycloak?.token) {
            conf.headers = { Authorization: `Bearer ${auth.session.keycloak.token}`, ...conf.headers };
          }
          resolve(conf);
        })
        .catch(reject);
    })),

    (error) => Promise.reject(error),
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.config?.headers?.hideError) {
        return Promise.reject(error);
      }
      if (!error.config || error.config.global !== false) {
        let errorText;
        // interpret response error
        if (error.response) {
          errorText = getMyErrorMessage(error);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          errorText = 'No response received';
        } else {
          // Something happened in setting up the request that triggered an Error
        }
        if (errorText) {
          toastr.error(errorText, 'Server Error!', {
            preventDuplicates: true,
            timeOut: 0,
            closeButton: true,
            onclick() {
              if (APPSettings.showBuildVersion) {
                bootbox.alert(`Additional info about the error:<br /><pre>${getErrorInfo(error)}</pre>`);
              }
            },
          });
        }
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}
