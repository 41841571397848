import Auth from './auth';
import Session, { eventBus } from './session';
import setupAxios, { getMyErrorMessage } from './setup-axios';

const auth = new Auth();

const axios = setupAxios(auth);

export { Session, Auth, axios, getMyErrorMessage, eventBus };

export default auth;
