import Session from './session';

class Auth {
  session: Session;

  constructor() {
    this.session = new Session();
  }

  check() {
    return this.session.check();
  }

  init() {
    return this.session.login();
  }

  hasResource(client: string) {
    if (!this.session?.keycloak?.resourceAccess) {
      return false;
    }

    return !!this.session.keycloak.resourceAccess[client];
  }

  hasResourceRole(client: string, role: string) {
    if (!this.session.keycloak || !this.hasResource(client)) {
      return false;
    }
    return this.session.keycloak.hasResourceRole(role, client);
  }

  hasRealmRole(role: string) {
    return this.session?.keycloak?.hasRealmRole(role) ?? false;
  }
}

export default Auth;
